.delete-file-button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 1rem;
  padding: 0;
}
.certificate-file-link, .support-material-link
{
  color: beige;
}
table > *{
  color: white
}